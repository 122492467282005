.megaMenuWrapper {
  position: absolute;
  width: 100%;
  z-index: 2;
  left: 0;
  right: 0;
  box-shadow: 0 10px 7px -4px #80808042;
  padding-top: 20px;
  transition: opacity 0.6s ease, transform 0.3s ease;

  @media (min-width: 768px) and (max-width: 1180px) {
    padding-top: 55px;
  }

  .innerDiv {
    background: #fff;
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 1px solid #f5f4f2;
  }

  .menuCategory {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e1e1e1;
    padding: 17px 0;
    transition: all 0.8s ease-in-out;

    img {
      width: 90px;
      height: auto;
      @media (min-width:768px) and (max-width:1080px){
       width: 50px;
      }
    }

    .notesIcon {
      height: 65px;
      @media (min-width:768px) and (max-width:1080px){
        height: 36px;
       }
       svg{
        width: 30px !important;
       }
    }

    h4 {
      color: #3d3935;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      text-align: left;
      white-space: no-wrap;
      @media (min-width:768px) and (max-width:1080px){
        font-size: 14px;
      }
    }

    .iconDiv {
      width: 100%;
      display: inline-block;
      svg {
        float: right;
        transition: all 0.3s ease-out;
        margin-right: 15px;
     
      }
    }

    &:hover {
      cursor: pointer;

      .iconDiv {
        svg {
          transform: translateX(10px);
        }
      }
    }
  }
}

.transparentElement {
  height: 10px;
  width: 100%;
}
