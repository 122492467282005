@import "../../styles/base/_baseModule.scss";

.signInWrapper {
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  padding: 4px 10px;
  a{
    margin-left: 4px;
    margin-bottom: 2px;
    &:hover{
      text-decoration: underline !important;
    }
  }
}
