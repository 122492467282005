@import "../../styles/variables/colors";
.full_page_loader {
  width: 100%;
  position: fixed;
  top: calc((var(--get-nav-height) + 100px)); 
  height: calc((var(--window_height, 1vh) * 100) - var(--get-nav-height));
  background: #fff;
  left: 0;
  z-index: 9;
  top: 140px;
  .container_img_wave {
    flex: 1 1 auto;
    min-height: auto;
    svg {
      width: 70%;
      max-width: 320px;
      @media (min-width: 1601px) {
        max-width: 290px;
      }
      @media (max-width: 767px){
        max-width: 196px;
      }
    }
  }
}
.container_img_wave {
  width: 100%;
  min-height: 408px;
  @media (max-width: 767px){
    min-height: 413px;
  }
  svg {
    width: 196px;
    .cls_1 {
      fill: Pri-clr(dark-blue);
    }
    .cls_2{fill:#fff;}
    .svg_animation {
      animation: rotate 15s infinite linear;
      transform-origin: center;
    }
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}
.spinner_wrapper{
  height: 50px;
}
.spinner_loader{
position: absolute;
top: 50%;
left: 20%;;
@media (max-width: 767px){
  left: 50%;
  top: 25%;
}
}