.skeltonContainer {
  background: #fff;
  border: 2px solid transparent;
  box-sizing: border-box;
  box-shadow: 0 0 3px hsla(0, 0%, 51%, 0.25098039215686274);
  border-radius: 7px;
  padding: 8px 6px;
  .imageSection {
    width: 100%;
    height: 90px;
  }

  .textSection {
    margin-top: 10px;
    width: 100%;
    height: 17px;
  }
.price{
    width: 27px;
    height: 12px;
}
  .btnSection {
    width: 100%;
    height: 30px;
    border-radius: 25px;
  }
}

.skelton {
  background-color: #e2e5e7;
  background-image: linear-gradient(
    90deg,
    rgba(#fff, 0),
    rgba(#fff, 0.5),
    rgba(#fff, 0)
  );
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}
