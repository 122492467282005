@import "../../styles/base/typography";
@import "../../styles/variables/colors";
@import "../../styles/base/fonts";
.try2_loader_wrapper {
  height: 100vh;
}
.try2Menu_wrap {
  .listing_items {
    display: block;
    padding-bottom: 75.25%;
    position: relative;
    z-index: 0;
    overflow: hidden;
    margin-bottom: 30px;
    img {
      position: absolute;
      top: 0;
      left: 9%;
      z-index: -1;
      height: 100%;
      max-width: unset;
    }
  }
}
.items_selector {
  gap: 16px;
  .item_slector_wrap {
    max-width: 137px;
  }
}
.optional_selector {
  border: 1px solid Sec-clr(lt-grey);
  box-shadow: 0px 0px 5px rgba(61, 57, 53, 0.1);
  border-radius: 15px;
  padding-top: 60px;
  padding-bottom: 30px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 5px #3d39351a;
  &.selected {
    padding-top: 10px
  }

  @media (max-width: 767.98px) {
    border-radius: 7px;
    padding-top: 28px;
    padding-bottom: 15px;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.category_selector {
  height: 100%;
  display: flex;
  flex-direction: column;
  .optional_selector {
    // flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    overflow: hidden;
    img {
      max-height: 162px;
      width: auto;
      @media (max-width: 575.98px) {
        max-height: 75px;
      }
    }
    &.optional_selector_selected {
      border: 3px solid Pri-clr(dark-blue);
      padding-top: 2vw;
      @media (max-width: 767.98px) {
        padding-top: 10px;
      }
    }
    &.optional_selector_selected.kids_combo_item_img{
      @media (max-width: 767.98px) {
        padding-top: 10px;
        min-height: 170px;
        max-height: 170px;
      }
    }
  }
}
.item_title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 18.2px !important;
  overflow: hidden;
  font-size: font(s14) !important;
  @media (max-width: 767.98px) {
    line-height: normal !important;
    font-size: font(s11) !important;
  }
}
.Menu_items_wrapper {
  .nav_wrap {
    gap: 30px;
    @media (max-width: 767.98px) {
      gap: 0;
    }
    .nav_item {
      max-width: calc(25% - 23px);
      width: calc(25% - 23px);
      &:last-child {
        @media (max-width: 767.98px) {
          margin-bottom: 30px;
        }
      }
      .Menu_item_wrap {
        padding: 0;
        color: #3d3935;
        @media (max-width: 767.98px) {
          display: flex;
          align-items: center;
        }
        .menu_img {
          @media (max-width: 767.98px) {
            max-width: 57%;
            margin-bottom: 0;
          }
        }
      }
      @media (max-width: 767.98px) {
        max-width: 100%;
        width: 100%;
        padding: 10px;
        box-shadow: none;
        border-bottom: 1px solid #e1e1e1;
        border-radius: 0;
      }
    }
  }
}
.item_main_div {
  cursor: pointer;
  padding: 20px;
  box-shadow: 0px 0px 5px rgba(61, 57, 53, 0.1);
  border-radius: 15px;
  position: relative;
  .item_img_wrap {
    text-align: center;
    .is_not_active {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  @media (max-width: 767.98px) {
    border-radius: 3px;
  }
}
.item_info_wrap {
  padding-left: 24px;
  padding-right: 24px;
  max-width: 565px;
  @media (min-width: 992px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  .selector_wrapper {
    .items_selector {
      gap: 16px;
      &.item_size_selector {
        .item_selector {
          width: calc(50% - 10px);
        }
      }
      @media (max-width: 575.98px) {
        gap: 6px;
        h6 {
          letter-spacing: normal;
          span {
            letter-spacing: normal;
          }
        }
      }
    }
  }
}
.card_header {
  padding: 38px 16px 16px;
}
.rounded_box {
  min-width: 95px;
  min-height: 95px;
  width: 95px;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 50px;
  @media (max-width: 767.98px) {
    min-width: 52px;
    min-height: 52px;
    width: 52px;
    height: 52px;
    margin-bottom: 8px;
  }
  span {
    display: inline-block;
    font-size: font(s32);
    @media (max-width: 767.98px) {
      font-size: font(s24);
    }
  }
}
.kids_combo_wrapper {
  .rounded_box {
    @media (max-width: 991.98px) and (min-width: 767.98px) {
      min-width: 75px;
      min-height: 75px;
      width: 75px;
      height: 75px;
    }
  }
  @media (max-width: 575.98px) {
    .kids_combo_row {
      margin-left: -4px;
      margin-right: -4px;
      .kids_combo_item {
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }
  @media screen and (max-width:360px) {
    .kids_combo_row {
      margin-left: 0px;
      margin-right: 0px;
      flex-wrap: nowrap;
    }
  }
}
.try2_item_order_btn {
  width: auto;
  max-width: unset !important;
  min-width: 170px !important;
  flex: 1 48%;
  padding: 9px 20px;
  margin: 1%;
  &.large_btn {
    width: 100%;
    max-width: 562px !important;
    @media (max-width: 767.98px) {
      max-width: 156px !important;
    }
  }
}
.item_increments {
  .increase_btns {
    background: url("../../images/plus-icon.svg") no-repeat;
    background-size: contain;
    width: 28px;
    min-width: 28px;
    height: 28px;
    line-height: 0;
    padding: 0;
    outline: none !important;
    box-shadow: none !important;
    font-size: 0;
    border: none !important;
    &.add_btn {
      background: url("../../images/plus-colored-icon.svg") no-repeat;
      background-size: contain;
    }
  }
  .decrease_btns {
    @extend .increase_btns;
    background: url("../../images/minus-icon.svg") no-repeat !important;
    background-size: contain !important;
    font-size: 0;
  }
  .counts {
    width: 60px;
    display: inline-block;
    padding: 0 10px;
    font-size: font(s18);
    font-weight: 500;
    text-align: center;
  }
  @media (max-width: 767.98px) {
    .increase_btns {
      background: none !important;
      width: auto;
      min-width: auto;
      height: auto;
      align-items: center;
      display: flex;
      &:before {
        content: "+";
        font-size: font(s24);
        font-family: font_fam(rale);
        font-weight: 300;
      }
    }
    .decrease_btns {
      &:before {
        content: "-";
      }
    }
  }
}
.item_detals {
  font-size: font(s10) !important;
  letter-spacing: normal !important;
}
.add_on_item_wrap {
  min-height: 100%;
  border: 3px solid transparent;
  box-shadow: 0px 0px 8.66509px #3d393533;
  &.selected {
    border-color: Pri-clr(dark-blue);
  }
  &.exclude {
    border-color: Sec-clr(dark-red);
  }
  @media (max-width: 575.98px) {
    padding: 20px 8px;
    p {
      line-height: 18px;
    }
  }
  &.set_min_height {
    min-height: 297px;
    @media (max-width: 1199.98px) {
      min-height: 238px;
    }
    @media (max-width: 1024.98px) {
      min-height: 231px;
    }
    @media (max-width: 991.98px) {
      min-height: 202px;
    }
    @media (max-width: 767.98px) {
      min-height: 178px;
      &.no_select_card {
        img {
          width: 45%;
        }
      }
    }
    @media (max-width: 575.98px) {
      min-height: 220px;
    }
    @media (max-width: 375.98px) {
      min-height: 183px;
    }
  }
  @media (max-width: 767.98px) {
    &.styleClass {
      box-shadow: 0px 2px 6px #82828240;
      border-radius: 7px;
      button {
        width: 100% !important;
      }
    }
  }
}
.swiper_wrapper {
  .add_on_item_wrap {
    max-width: 100%;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 8.66509px #3d393533;
  }
}
.try2_item_img_wrap {
  @media (max-width: 575.98px) {
    padding: 0;
    .try2_item_order_btn {
      min-width: 135px !important;
      font-size: font(s13);
      padding: 9px 10px;
    }
  }
}
.try2_selected_item_content {
  span {
    width: 100%;
    @media (max-width: 767.98px) {
      letter-spacing: 0.8px;
    }
  }
}

@media (max-width: 767.98px) {
  .try2_title_content{
    p{
      min-height: 26px;
    }
  }
}
.customize_content_wrapper {
  margin-left: 32px;
  margin-right: 32px;
  @media (max-width: 767.98px) {
    margin-left: 24px;
    margin-right: 24px;
  }
}
.item_details_btn_wrapper {
  .item_details_btn {
    button {
      @media (max-width: 1199.98px) {
        padding: 9px 15px;
      }
    }
  }
  @media (max-width: 991.98px) and (min-width: 767px) {
    flex-direction: column;
    .item_details_btn {
      width: 100% !important;
    }
  }
}
.item_details_row_wrap {
  @media (max-width: 767.98px) {
    margin-left: 0;
    margin-right: 0;
  }
}
.item_subtitle_wrap {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  height: 58px;
  overflow: hidden;
  position: relative;
  // &::after {
  //   content: "";
  //   text-align: right;
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  //   width: 70%;
  //   height: 1.2em;
  //   background: linear-gradient(to right, #ffffff00, #ffffff 50%);
  // }
  .item_subtitle_button {
    // position: absolute;
    // bottom: 0;
    // z-index: 1;
    // right: 0;
    color: Pri_clr(dark-blue);
  }
}
.try_show_more {
  height: 25px;
  margin-bottom: 5px;
}

.description_greater {
  height: 77px;
}
.inn_title_wrap {
  @media (max-width: 767.98px) {
    line-height: 1.5 !important;
  }
}
.swiper_wrapper {
  .no_dressing_card {
    justify-content: center;
    .items_image {
      flex: 0 0 auto !important;
      margin-bottom: 25px !important;
    }
  }
}
@media (min-width: 992px) {
  .dressing_items {
    max-width: calc(24.66% - 20px) !important;
    width: calc(24.66% - 20px) !important;
  }
}
// .item_details_row_wrap {
//   .car_col {
//     &:last-child {
//       .favbtn {
//         display: block !important;
//       }
//     }
//   }
// }
.selected_kids_combo {
  min-width: auto !important;
}

.no_Dressing_Card {
  max-width: calc(33.33% - 9px);
  width: calc(33.33% - 9px);
  min-width: calc(32.33% - 9px);
  .no_Dressing_Card_Border_radius {
    border-radius: 7px;
  }
}
.align_spiner_end {
  align-items: flex-end !important;
}

@-moz-document url-prefix() {
  .dressing_items_scrollbar {
    padding: 20px 5px !important;
  }
}
.dressing_items_scrollbar {
  overflow-x: auto;
  overflow-y: hidden;
  padding: 10px 5px;
  &::-webkit-scrollbar {
    border-radius: 5px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #767676;
    border-radius: 10px !important;
    background-clip: content-box;
  }
}

@media (min-width: 767px) {
  .try2_combo_optional_selector {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0 !important;
    img {
      max-height: 75px !important;
      max-width: 110px !important;
    }
    .title_font_size {
      font-size: 1.2vw;
      text-align: left;
      line-height: 100%;
    }
    .rounded_box {
      min-width: 70px;
      min-height: 70px;
      width: 70px;
      height: 70px;
      margin: 16px 0 16px 10px !important;
    }
    .try2combo_inner_div {
      display: flex;
      align-items: center;
    }
    .edit_btn{
      background: Pri-clr(dark-blue);
    color: #fff;
    width: 100%;
    padding: 2px;
    letter-spacing: 0.5px;
    }
  }
}
.try2_combo_optional_selector {
  @media (max-width: 767px) {
    align-items: baseline;
    justify-content: space-between !important;
  }
}

.order_name_form_wrap {
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
}
.kids_combo_item_img{
  .kids_img_item {
    @media (min-width: 992px) {
      min-height: 170px;
      display: flex;
    align-items: center;
    justify-content: center;
    }
  }
}