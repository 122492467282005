@import "../../../styles/base/baseModule";
.my_card_modal {
    .modal_close_button {
      position: static;
      // height: 18px;
      // width: 18px;
      // top: 0;
      // right: 0;
    }
    .card_title {
      font-size: font(s22);
      @media (max-width: 767.98px) {
        font-size: font(s22);
      }
    }
    .card_increments {
      border: 1px solid #000;
      border-radius: 6px;
      width: 155px;
      justify-content: space-between;
    }
    .empty_content_wrap {
      padding: 20px 0;
      max-width: 290px;
    }
    .items_list {
      padding: 20px 23px 50px;

      .list_item {
        border: 1px solid #cccccc;
        box-shadow: 0px 0px 8.66509px rgba(61, 57, 53, 0.2);
        border-radius: 6px;
        padding: 18px 17px;
        margin-bottom: 14px;

      }
      &.items_list_invite {
        padding-bottom: 0;
      }
    }
    .shadow_box {
      border: 1px solid #cccccc;
      box-shadow: 0px 0px 8.66509px rgba(61, 57, 53, 0.2);
      border-radius: 6px;
      padding: 18px 17px;
      margin-bottom: 14px;
      margin: 0 23px;
      width: calc(100% - 46px);
    }
    .modal_header,
    .checkout_btn_wrap {
      position: absolute;
      background: #fff;
      width: 100%;
      z-index: 99;
      right: 0;
      top: 0;
    }
    .checkout_btn_wrap {
      bottom: 0;
      top: auto;
    }
   
    &.sticky_modal_content {
      .modal_header,
      .checkout_btn_wrap {
        position: fixed;
        background: #fff;
        width: 100%;
        z-index: 99;
        right: 17px;
        top: 0;
        box-shadow: 0px 0px 8.66509px #3d393533;
        @media (min-width: 576px) {
          max-width: 500px;
        }
        @media (max-width: 1024.98px) {
          right: 0;
        }
      }
      .checkout_btn_wrap {
        bottom: 0;
        top: auto;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }


  .promo_code{
    padding: 0px 23px 30px;
    .arrow_btn{
      position: absolute;
      width: 28px;
      height: 28px;
      padding: 0;
      top: 50px;
      right: 10px;
      font-size: 0;
      outline: none !important;
      box-shadow: none !important;
      background: url('#{$REACT_APP_CLOUD_FRONT}/images/Location/location_farwards_blue.svg')
      no-repeat;
      background-size: contain;
    }
    .loader_wrapper{
      position: absolute;
      width: 28px;
      height: 28px;
      padding: 0;
      top: 50px;
      right: 10px;
      .spinner {
        width: 24px !important;
        height: 24px !important;
        border: 0.2em solid #000;
        border-right-color: transparent;
        border-radius: 50%;
    }
    }
  .close_btn{
    color: #fff;
    position: absolute;
    top: 57%;
    background: Pri-clr(dark-blue);
    border-radius: 50%;
    right: 10px;
    border: 0;
    padding: 1PX;
    cursor: pointer;
  }
  }

 
  .promoInputBtn{
    position: relative;
    .arrow_btn, .loader_wrapper{
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      right: 0%;
    }

  }