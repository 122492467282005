.card_increments {
  border: 1px solid #000;
  border-radius: 6px;
  width: 155px;
  justify-content: space-between;
}

.items_list {
  padding: 20px 23px 50px;
}

.list_item {
  border: 1px solid #cccccc;
  box-shadow: 0px 0px 8.66509px rgba(61, 57, 53, 0.2);
  border-radius: 6px;
  padding: 18px 17px;
  margin-bottom: 14px;
}
.item_price_cart {
  position: absolute;
  right: 0;
  top: 10%;
  @media screen and (max-width: 767.98px) {
    top: 20%;
}
}

.cart_spinner{
    width: 100%;
    position: relative !important;
    top: 0 !important;
    height: auto !important;
    svg {
      width: 70%;
      max-width: 120px !important;
      }
  }
