.guest_login_modal{
      :global{
        .modal-close-button{
            background-image: url('../../../../../src/images/Blueclose.svg') !important;
            width: 32px;
            height: 32px;
            right: 0;
        }
      }
    }


