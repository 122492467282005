@import "../../styles/base/_baseModule";
.live_tracker_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.public_live_tracking_wrapper {
  padding-top: 160px;
  @media (max-width: 767.98px) {
    padding-top: 140px;
  }
}
.live_tracking_modal {
  .back_btn {
    position: absolute;
    top: -24px;
    left: 0;
  }
  .Live_tracking_products_Scrollbar {
    padding: 0 5px;
    &::-webkit-scrollbar {
      border-radius: 5px;
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #767676;
      border-radius: 10px !important;
    }
  }
  h3 {
    font-family: "Clarendon LT Std" !important;
  }
  .map_wrapper {
    height: 380px;
    @media (max-width: 767.98px) {
      height: 275px;
    }
  }
  .border_column {
    @media (min-width: 768px) {
      border-right: 1px solid #cccccc;
    }
  }
  .back_icon {
    position: absolute;
    left: -10px;
    bottom: 50%;
    padding: 10px;
  }
  .front_icon {
    position: absolute;
    bottom: 50%;
    right: -8px;
    padding: 10px;
  }
}
.live_tracking_stepper {
  .live_steper_par {
    padding-top: 20px !important;
    padding-bottom: 0 !important;
  }
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      &::after {
        left: 125%;
        width: 160px;
        @media (max-width: 767.98px) {
          width: 65px;
          top: 40%;
        }
      }
    }
  }
}
.live_tracking_circle {
  width: 50px !important;
  height: 50px !important;
  margin-left: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 40px;
    margin: 0 auto;
    height: 40px;
  }
}
.pickup_location_img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}
.scroll_card_detail {
  max-height: 370px;
  overflow-x: hidden;
  overflow-y: visible;
  padding-right: 10px;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }
  &::-webkit-scrollbar-thumb {
    background: #767676;
    height: 100px;
  }
  @include media-breakpoint-down(md) {
    max-height: 100%;
  }
}

.tableEndSpacing {
  padding-right: 25px;
  @media (max-width: 767px) {
    padding-right: 15px;
  }
}

.favoriteButtonStyles {

    position: absolute;
    right: 10px;
    top: -2px;
  @media (max-width:767.98px) {
    right: 10px;
    top: 19px;
  }

}

