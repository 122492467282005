@import '../base/_typography.scss';
@import '../variables/_colors.scss';
@import '../base/_fonts.scss';
@import '../base/cloudfront_url_base.scss';
@import "~bootstrap/scss/bootstrap";

@mixin font_sty($fs_size:null,$fs_color:null,$fs_family: null,
$xxl_fs:null,$lg_fs:null,$md_fs:null,$sm_fs:null,$xs_fs:null,
$xxl_ff: null,$lg_ff:null,$md_ff:null,$sm_ff:null,$xs_ff:null,
$fs_weight: null,$xxl_weight: null,
$xxl_lh:null,$lg_lh:null,$md_lh:null,$sm_lh:null,$xs_lh:null,$fs_lh:null,
$fs_sty: null,$fs_dec: null,
$xxl_align:null,$lg_align:null,$md_align:null,$sm_align:null,$xs_align:null,$fs_align:null,
$xxl_space:null,$lg_space:null,$md_space:null,$sm_space:null,$xs_space:null,$fs_space:null,
$fs_zindex: null,$sm_sty: null)
{
  font-size: $fs_size;
  color: $fs_color;
  font-family: $fs_family;
  font-weight: $fs_weight;
  line-height: $fs_lh;
  text-transform: $fs_sty;
  text-decoration: $fs_dec;
  text-align: $fs_align;
  z-index: $fs_zindex;
  letter-spacing: $fs_space;

  @media (min-width: 1601px){
    font-size: $xxl_fs;
    font-family: $xxl_ff;
    line-height: $xxl_lh;
    text-align: $xxl_align;
    letter-spacing: $xxl_space;
    font-weight: $xxl_weight;
    
  }
  @media (max-width: 1199.98px){
    font-size: $lg_fs;
    line-height: $lg_lh;
    text-align: $lg_align;
    letter-spacing: $lg_space;
    font-family: $lg_ff;
  }
  @media (max-width: 991.98px){
    font-size: $md_fs;
    line-height: $md_lh;
    text-align: $md_align;
    letter-spacing: $md_space;
    font-family: $md_ff;
  }
  @media (max-width: 767.98px){
    font-size: $sm_fs;
    line-height: $sm_lh;
    text-align: $sm_align;
    letter-spacing: $sm_space;
    font-family: $sm_ff;
    text-transform: $sm_sty;
  }
  @media (max-width: 575.98px){
    font-size: $xs_fs;
    line-height: $xs_lh;
    text-align: $xs_align;
    letter-spacing: $xs_space;
    font-family: $xs_ff;
  }
}

// Theme Buttons
@mixin theme_button ($button_color: $orange,$set_radius:25px,) {
    border: 2px solid;
    background-color: transparent;
    border-radius: $set_radius;
    color: $button_color;
    border-color: $button_color;
    font-family: font_fam(Vcf_bold);
    text-align: center !important;
    position: relative;
    z-index: 0;
  
    &:before {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background-color: $button_color;
      border-radius: $set_radius;
      z-index: -1;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      transform: scaleX(0);
    }
  
    &:hover:before {
      transition: all 0.4s ease-in-out;
      opacity: 1;
      transform: scaleX(1);
    }
  
    &:hover {
      color: #fff !important;
      transition: all 0.4s ease-in-out;
      text-decoration: none !important;
      outline: none !important;
    }
    &:visited,
    &:active,
    &:focus,&:focus-visible {
      background-color: white!important;
      outline: none !important;
    }
}

@mixin btn_theme ($button_color: $orange,$set_radius:25px,$button_hover_clr:$orange, $btn_hover_text: font_clr(white-clr),$btn_theme_clr:font_clr(white-clr)) {
  border: 1px solid;
  background-color: $button_color;
  border-radius: $set_radius;
  color: $btn_theme_clr;
  border-color: $button_color;
  font-family: font_fam(Vcf_bold);
  text-align: center !important;
  display: inline-block;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  text-decoration: none !important;
  box-shadow: 0px 0px 11px rgba(61, 57, 53, 0.2);
  &:hover,&:disabled{
    background-color: $button_hover_clr !important;
    outline: none !important;
    box-shadow: 0px 0px 11px rgba(61, 57, 53, 0.2);
    border-color: $button_hover_clr;
    color: $btn_hover_text;
  }
  &:disabled {
    opacity: 0.5;
  }
  &:active,
  &:focus,&:focus-visible {
    background-color: $button_hover_clr !important;
    outline: none !important;
    box-shadow: 0px 0px 11px rgba(61, 57, 53, 0.2);
    border-color: $button_hover_clr;
    color: $btn_hover_text;
  }
}

// Theme Forms
@mixin inputfields($fld_clr: font_clr(d-color),$fld_ff:'Raleway',$ph_clr: font_clr(d-color)) {
  color: $fld_clr;
  font-size: font(s14);
  font-family: $fld_ff;
  margin-top: 10px;
  border: none;
  border-bottom: 1.5px solid #b0b0b0;
  border-radius: 0;
  padding-bottom: 5px;
  margin-bottom: 5px;
  padding-left: 0;
  @extend %Removed_extra_sty;
  &::-webkit-input-placeholder {
    color: $ph_clr;
  }
  &::-moz-placeholder {
    color: $ph_clr;
  }
  &:-ms-input-placeholder {
    color: $ph_clr;
  }
  &:-moz-placeholder {
    color: $ph_clr;
  }
  &:focus,&:focus-visible {
    color: $ph_clr;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: unset;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent;
  }
}

// New Form Design
@mixin form_label() {
  @include font-sty(font(s14),Pri_clr(lt-black),font-fam(Vcf),$fs_space: 0.03rem,$fs-sty:uppercase);
}

@mixin new_form_control(){
  @include font_sty(font(s14),Pri_clr(lt-black),font_fam(Visby-cf));
  height: 51px;
  font-weight: 500;
  border: none;
  background-color: #fff;
  box-shadow: inset 0px 0px 6px #44444440;
  border-radius: 9px;
  &:hover,&:focus,&:focus-visible,&:active {
    outline: none !important;
  }
  &::-webkit-input-placeholder {
    color: Sec_clr(text-grey);
    font-size: font(s14);
    font-family: font_fam(Visby-cf);
    font-weight: 500;
  }
  &::-moz-placeholder {
    color: Sec_clr(text-grey);
    font-size: font(s14);
    font-family: font_fam(Visby-cf);
    font-weight: 500;
  }
  &:-ms-input-placeholder {
    color: Sec_clr(text-grey);
    font-size: font(s14);
    font-family: font_fam(Visby-cf);
    font-weight: 500;
  }
  &:-moz-placeholder {
    color: Sec_clr(text-grey);
    font-size: font(s14);
    font-family: font_fam(Visby-cf);
    font-weight: 500;
  }  
  &.placeholder_text::-webkit-datetime-edit {
    color: Sec_clr(text-grey);
    font-size: font(s14);
    font-family: font_fam(Visby-cf);
    font-weight: 500;
  }  
  &.placeholder_text::-webkit-datetime-edit-text {
    color: Sec_clr(text-grey);
    font-size: font(s14);
    font-family: font_fam(Visby-cf);
    font-weight: 500;
  }  
  &.placeholder_text::-webkit-calendar-picker-indicator {
    color: Sec_clr(text-grey);
    font-size: font(s14);
    font-family: font_fam(Visby-cf);
    font-weight: 500;
    opacity: 0.6;
  }
  &:focus,&:focus-visible {
    color: Pri_clr(lt-black);
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: inset 0px 0px 6px #44444440;
    outline: none !important;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent;
  }
}
  @mixin toolTip_color ($color:null) {
    .tooltip-inner {
      background-color: $color;
    }
    .arrow::before {
      border-bottom-color: $color;
    }
  }