@import "../../styles/base/_baseModule.scss";

.details_wrapper {
  .details_list_wrap {
    margin-bottom: 50px;
    li {
      margin-bottom: 9px;
      img {
        width: 20px;
        margin-right: 5px;
      }
    }
  }
}
.sign_up_wrapper,
.sign_in_wrapper {
  padding-left: 7px;
  padding-right: 7px;
}
.listing_style {
  ul {
    li {
      padding: 29px 0;
      &:first-child {
        border-top: 0;
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.profile_wrapper {
  .user_info_wrap {
    ul {
      .inner_list_wrap {
        margin-bottom: 16px;
        font-size: font(s16);
        .info_label {
          font-size: font(s14);
          font-family: "Visby CF Extra";
          text-transform: uppercase;
        }
        .info_text {
          font-size: font(s14);
          font-family: "Visby CF";
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .date_icon_container {
    position: absolute;
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);
    z-index: -1;
  }
  @-moz-document url-prefix() {
    .date_icon_container{
      display: none;
    }
  }
  .scroll_spacing {
    padding-left: 35px;
  }
}
.card_info_wrap {
  @extend .user_info_wrap;
}
.profile_inn_wrap {
  margin: 50px 0 24px 0;
  @include media-breakpoint-down(md) {
    margin-top: 0px;
  }
  .edit_remove_btn {
    position: relative;
    &::before {
      content: "";
      background-color: #f0f0f0;
      width: 2px;
      height: 70px;
      position: absolute;
    }

    @include media-breakpoint-down(md) {
      border-left: none;
      display: flex;
      button {
        margin-top: 0 !important;
      }
    }
    button {
      max-width: 100px;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      img {
        margin-right: 5px;
      }
    }
  }
}
.profile_content_wrap {
  .inn_content_wrap {
    max-width: 560px;
    img {
      margin-bottom: 35px;
      width: 120px;
      @media (max-width: 767.98px) {
        width: 74px;
        margin-bottom: 24px;
      }
    }
    h1 {
      margin-bottom: 18px;
      @media (max-width: 767.98px) {
        margin-bottom: 13px;
      }
    }
    p {
      width: 70%;
      margin-bottom: 38px;
      @media (max-width: 767.98px) {
        width: 100%;
        margin-bottom: 31px;
      }
    }
  }
  .recent_order_list {
    border-bottom: 1px solid #f0f0f0 !important;
    padding: 24px 0 !important;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      display: flex;
    }
    .recent_order_content {
      max-width: 244px;
      width: 100%;
      @include media-breakpoint-down(md){
        max-width: 100%;
      }
      img{
        width: 18px;
      }
    }
    .recent_order_btns {
      @include media-breakpoint-down(md) {
       justify-content: space-between;
       align-items: center;
      }
      button {
        position: relative;
        background-color: Pri_clr(dark-blue);
        padding: 3px 13px;
        color: #fff;
        border-radius: 25px;
        border: none;
        box-shadow: none;
      }
    }
    li{
      list-style-type: none;
    }
    .viewLessBtn{
      background: transparent;
    border: none;
    font-size: 14px;
    display: flex;
    text-wrap: nowrap;
    width: 100%;
    align-items: center;
    font-weight: 300;
    font-family: 'Raleway';
  margin-top: 10px;
  color: Pri_clr(dark-blue);
  padding-left: 0;
  margin-left: -3px;
  @include media-breakpoint-down(md) {
    margin-left: -2px;
  }
    svg{
      width: 24px;
      height: 24px;
      color: Pri_clr(dark-blue);
    }
    }
  }

  .favorite_items_wrapper {
    .favorite_card {
      cursor: pointer;
      box-shadow: 0px 0px 8.66509px #3d393533;
      border-radius: 15px;
      .favourite_icon_wrap {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
        outline: none !important;
        padding: 0;
        box-shadow: none !important;
        border: 0;
      }
      img {
        margin: 0 !important;
      }
      &.item_disabled {
        &::after {
          content: "";
          background-color: #fff;
          opacity: 0;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
        }
      }
    }
    .favorite_item_view_more_btn {
      border-radius: 0px 0px 9px 9px;
      height: 43px;
      box-shadow: 0px 0px 8.66509px #3d393533 !important;
    }
  }
}
.group_order_wrap {
  @media (min-width: 767px) {
    height: calc(100vh - var(--get-nav-height));
    .invite_content_column {
      max-height: calc(100vh - var(--get-nav-height));
      overflow: auto;
      .invite_content_wrap {
        max-width: 562px;
      }
    }
  }
}
.add_vehicle_btn {
  &:disabled {
    pointer-events: none;
  }
}
.welcome_banner_wrap {
  min-height: calc(100vh - var(--get-nav-height));
  height: calc(100vh - var(--get-nav-height));
  div {
    height: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: top;
      @include media-breakpoint-down(md){
        height: 76%;
      }
    }
  }
}
.welcome_content_wrap {
  @include media-breakpoint-down(md) {
    position: absolute;
    bottom: 8%;
    left: 0;
    padding: 0;
    .welcome_inner_content {
      background: #ffffff;
      box-shadow: 0px 2px 6px #82828240;
      border-radius: 7px;
      padding: 24px 33px;
      width: calc(100% - 48px);
      margin: 0 auto;
    }
  }
}
.account_content_wrapper {
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.menu_items_scrollbar {
  max-width: calc(100vw - 96px);
  width: 100%;
  height: 55px !important;
  background-color: white;
  border-bottom: 1px solid #21212114;
  @media (max-width: 767.98px) {
    max-width: calc(100vw - 48px);
    width: calc(100vw - 48px) !important;
  }
  .Scrollbar_view {
    width: auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;
    overflow-y: hidden !important;
    padding: 0 7px;
  }
  .Scrollbar_thumb {
    background-color: Sec_clr(lt-grey);
    border-radius: 20px;
  }
}


.select_radio_button {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include media-breakpoint-down(md) {
    top: 14px;
  }
  input[type="radio"] {
    border-radius: 11px;
    width: 14px;
    height: 14px;
    display: block;
    background: Pri_clr(dark-blue);
    cursor: pointer;
    accent-color: Pri_clr(dark-blue);
  }

  input[type="radio"]:checked {
    border-radius: 11px;
    width: 14px;
    height: 14px;
    content: " ";
    display: block;
    background: Pri_clr(dark-blue);
    accent-color: Pri_clr(dark-blue);
  }
}
.delivery_select_radio_button{
  top: 30% !important;
}
.all_recent_orders_wrapper {
  margin-top: 20px;
  @media (max-width: 767.98px) {
    margin-top: 10px;
  }
  .search_bar_wrap {
    background: #ffffff;
    border: 1px solid #767676;
    padding: 5px 14px;
    .search_inn_content {
      background-color: #fff;
      height: 51px;
      .search_bar_icon {
        img {
          width: 30px;
        }
        border-right: 1px solid #cccccc;
        padding: 0 14px 0 0;
      }
      .form_control {
        border: none;
        box-shadow: none !important;
        outline: none !important;
        border-radius: 0;
      }
    }
    .Search_active {
      .menu_tabs {
        display: none;
      }
    }
    @media (max-width: 767.98px) {
      top: 194px;
      .form_control {
        width: calc(100% - 50px) !important;
      }
      .search_bar_icon {
        img {
          width: 21px !important;
        }
      }
    }
  }
  .sorting {
    border-bottom: 1px solid #cccccc;
    padding: 35px 0;
    @media (max-width: 767.98px){
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
    .dropdown {
      button {
        background: transparent !important;
        border: none !important;
        color: #000 !important;
      }
    }
  }
}

.more_recent_spinner{
  position: relative;
  .basic_loader{
    left: 50%;
    top: 20%;
    @include media-breakpoint-down(md){
      left: 45%;
    }
  }
}

.favorite_card_disabled{
  filter: opacity(0.6);
  cursor: not-allowed !important;
}
.account_order_title{
  @include media-breakpoint-up(md){
   white-space: nowrap;
  }
  @include media-breakpoint-down(md){
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
   }
}
// @-moz-document url-prefix(){
//   input[type=date] {
//   --bw: 15px;
//   --a: calc(100% - var(--bw));
//   --b: calc(var(--a) - 17px);
//   border: var(--bw) solid #000;
//   clip-path: polygon(0% 0%, 0% 100%,
//                      var(--b) 100%, var(--b) var(--bw),
//                      var(--a) var(--bw), var(--a) var(--a),
//                      var(--b) var(--a), var(--b) 100%,
//     100% 100%, 100% 0%);}
// }
.delete_account_wrapper{
  max-width: 500px;
  margin: 0 auto;
  height: 300px;
   display: flex;
  flex-direction: column;
  justify-content: center;
}