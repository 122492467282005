@import "../../styles/base/_baseModule.scss";
.topHeaderWrapper {
  background: Pri-clr(dark-blue);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0;
  gap: 16px;
  .switcher {
    padding: 4px;
    border-radius: 50px;
    gap: 4px;
    background: #fff;
    display: flex;
    justify-content: space-around;
    @media (max-width: 767.98px) {
      padding: 2px;
    }
    .switcherLink {
      font-family: font-fam(Visby-cf);
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0.05em;
      text-align: center;
      color: #3d3935;
      text-decoration: none;
      padding: 3px 8px;
      border-radius: 25px;
      text-transform: uppercase;
      transition: all ease 0.3s;
      &:nth-child(2):hover {
        background: #aa182c;
        color: #fff;
      }
      @media (max-width: 767.98px) {
        font-size: 11px;
      }
    }
    .activeLink {
      background: Pri-clr(dark-blue);
      color: #fff;
    }
   
  }
  .cateringText {
    margin-left: 16px;
  }
  .locationNavSection {
    font-family: font-fam(Visby-cf);
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #fff;
    background: transparent;
    border: 0;
    text-decoration: underline;
    text-transform: uppercase;
  }
  .SignInBtn {
    font-family: "Visby CF";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.42px;
    text-transform: uppercase;
    color: #fff;
  }
}
