@import "~bootstrap/scss/bootstrap";

// $grid-gutter-width: 1.875rem !important;
.container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  @include media-breakpoint-up(xxl){
    max-width: 1140px;
  }
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}
button{
  &.close {
    padding: 0;
    background-color: transparent;
    border: 0;
  }
}
.form-group {
  margin-bottom: 1rem;
}
.modal-header {
  align-items: flex-start;
}
.cursor-default{
  cursor: default !important;
}
