@import "../base/cloudfront_url_base.scss";

@font-face {
  font-family: "visby_cfextra_bold";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visbycf-extrabold-webfont.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visbycf-extrabold-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova Lt";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/ProximaNova-Semibold.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/ProximaNova-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/ProximaNova-Semibold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/ProximaNova-Semibold.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/ProximaNova-Semibold.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// Visby CF Extra
@font-face {
  font-family: "Visby CF Extra";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Visby-CF-Extra-Bold/#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-ExtraBold.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Visby-CF-Extra-Bold/#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Visby-CF-Extra-Bold/#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-ExtraBold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Visby-CF-Extra-Bold/#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-ExtraBold.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Visby-CF-Extra-Bold/#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-ExtraBold.ttf")
      format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

$fonts_family: (
  "Vcf_bold": "visby_cfextra_bold",
  "pn_lt": "Proxima Nova Lt",
  "rale": "Raleway",
  "Vcf": "Visby CF Extra",
  "Cls": "Clarendon LT Std",
  "Visby-cf": "Visby CF",
  "Trade-regular": "Trade Gothic LT Std",
  "Trade-bold": "Trade Gothic Bold Std",
  "Trade-cond": "Trade Gothic CondEighteen Std",
);

@function font_fam($font_fam) {
  @return map-get($fonts_family, $font_fam);
}

@each $fonts, $font_val in $fonts_family {
  .font-#{$fonts} {
    font-family: $font_val !important;
  }
  @media (max-width: 767.98px) {
    .font-sm-#{$fonts} {
      font-family: $font_val !important;
    }
  }
}
.Vcf_bold {
  font-family: "visby_cfextra_bold" !important;
}
.pn_lt {
  font-family: "Proxima Nova Lt" !important;
}
.Rale {
  font-family: "Raleway" !important;
}

// New Fonts
@font-face {
  font-family: "Raleway";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Bold.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Bold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Bold.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Bold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-ExtraBold.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-ExtraBold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-ExtraBold.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-ExtraBold.ttf")
      format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Light.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Light.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Light.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Light.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Light.ttf")
      format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Medium.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Medium.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Medium.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-SemiBold.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-SemiBold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-SemiBold.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-SemiBold.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Regular.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Regular.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Regular.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Raleway/Raleway-Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Visby CF";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Regular.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Visby CF";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Thin.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Thin.woff")
      format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Visby CF";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Light.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Light.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Visby CF";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Medium.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Medium.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Visby CF Demi";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-DemiBold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-DemiBold.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Visby CF Extra";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-ExtraBold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-ExtraBold.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Visby CF";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Bold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Bold.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Visby CF";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Heavy.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/visby-cf/VisbyCF-Heavy.woff")
      format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Clarendon LT Std";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Bold.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Bold.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Bold.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Bold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Clarendon LT Std";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Clarendon LT Std";
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Light.eot");
  src: url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Light.eot?#iefix")
      format("embedded-opentype"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Light.woff2")
      format("woff2"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Light.woff")
      format("woff"),
    url("#{$REACT_APP_CLOUD_FRONT}/fonts/Clarendon/ClarendonLTStd-Light.ttf")
      format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Marydale;
  src: url("../../fonts/Marydale.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Trade Gothic LT Std";
  src: url("../../fonts/TradeGothic-LT-Regular/TradeGothic-LT-Regular.ttf");
}
@font-face {
  font-family: "Trade Gothic Bold Std";
  src: url("../../fonts/TradeGothic-LT-Bold/TradeGothic-LT-Bold.ttf");
}
@font-face {
  font-family: "Trade Gothic CondEighteen Std";
  src: url("../../fonts/TradeGothic-LT-CondEighteen-Bold/TradeGothic-LT-CondEighteen-Bold.ttf");
}

@font-face {
  font-family: "Trade Gothic CondEighteen Std";
  src: url("../../fonts/TradeGothic-LT-CondEighteen-Bold/TradeGothic-LT-CondEighteen-Bold.ttf");
}

@font-face {
  font-family: "FreightDispCmp Pro";
  src: url("../../fonts/freightFont/zupasfr8italic-webfont.woff")
  format('woff')
}
