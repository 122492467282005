.checkoutwrapper {
  h4,
  ul {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.btnColor {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 20px !important;
  button {
    text-align: center !important;
    display: flex;
    justify-content: center !important;
  }
  span {
    display: none !important;
  }
}
.steppers {
  ul {
    li {
      list-style-type: none;
      position: relative;
      .border_line {
        background-color: #afafaf;
        width: 100px;
        height: 100px;
        position: relative;
        @media screen and (max-width: 767px) {
          width: 50px;
          height: 50px;
        }
        &::before {
          content: "";
          background-color: inherit;
          height: 2px;
          position: absolute;
          right: 130%;
          width: 120px;
          top: 40%;
          @media screen and (max-width: 1100px) {
            width: 90px;
          }
          @media screen and  (min-width:821px) and (max-width: 992px) {
          
            width: 40px;
          }
          @media screen and  (min-width:768px) and (max-width: 820px) {
            right: 55px;
            width: 40px;
          }
          @media screen and  (min-width:651px) and (max-width: 767px) {
            right: 101px;
            width: 90px;
          }
          @media screen and (min-width:551px) and (max-width:650px) {
            right: 100px;
          }
          @media screen and (min-width:401px) and (max-width:550px) {
            right: 170%;
            width: 40px;
          }
          @media screen and (min-width:360px) and  (max-width:400px) {
            right: 130%;
            width: 50px;
          }
          @media (max-width:359px) {
            right: 110%;   
            width: 60px;
          }
        }
      }
      &:first-child {
        .border_line {
          &::before {
            display: none;
          }
        }
      }

      .border_line_public_tracking {
        background-color: #afafaf;
        width: 100px;
        height: 100px;
        position: relative;
        @media screen and (max-width: 767px) {
          width: 150px;
          height: 50px;
        }
        &::before {
          content: "";
          background-color: inherit;
          height: 2px;
          position: absolute;
          right: 140%;
          width: 200px;
          top: 50%;
          z-index: -1;
          @media screen and (max-width: 400px) {
            width: 98px;
            right: 99% !important;
          }
          @media screen and (min-width: 401px) and (max-width: 500px) {
            width: 90px;
            right: 115%;
          }
          @media screen and (min-width: 501px) and (max-width: 767px) {
            width: 100px;
            left: 50%;
            transform: translateX(-170%);
          }
          @media screen and (min-width: 768px) and (max-width: 991px) {
            width: 100px;
            left: 50%;
            transform: translateX(-122%);
          }
          @media screen and (min-width: 992px) and (max-width:1199px) {
            right: 125%;
            width: 120px;
          }
          @media screen and (min-width: 1200px) and (max-width:1399px) {
            right: 170%;
            width: 120px;
          }
        }
      }
      &:first-child {
        .border_line_public_tracking {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}
.map_wrapper {
  height: 430px;
  @media (max-width: 767px) {
    height: 180px;
  }
}
.notification_wrap {
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(130, 130, 130, 0.25);
  border-radius: 7px;
}

.steppers_img_size {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90px;
  height: auto;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 767px) {
    width: 30px;
    height: auto;
  }
}

.feedbackModalWrapper{
  .starIcon{
    cursor: pointer;
  }
  .starOn {
    path {
      fill: #f9ca25;
    }
  }

}