
$font_sizes:(
    's0' : 0,
    's8' : 0.5rem,
    's10' : 0.625rem,
    's11' : 0.6875rem,
    's12' : 0.75rem,
    's13' : 0.8125rem,
    's14' : 0.875rem,
    's15' : 0.9375rem,
    's16' : 1rem,
    's17' : 1.0625rem,
    's18' : 1.125rem,
    's19' : 1.1875rem,
    's20' : 1.25rem,
    's21' : 1.3125rem,
    's22' : 1.375rem,
    's23' : 1.4375rem,
    's24' : 1.5rem,
    's25' : 1.5625rem,
    's26' : 1.625rem,
    's27' : 1.6875rem,
    's28' : 1.75rem,
    's29' : 1.8125rem,
    's30' : 1.875rem,
    's32' : 2rem,
    's33' : 2.0625rem,
    's34' : 2.125rem,
    's35' : 2.1875rem,
    's36' : 2.25rem,
    's37' : 2.3125rem,
    's40' : 2.5rem,
    's41' : 2.5625rem,
    's43' : 2.6875rem,
    's45' : 2.8125rem,
    's46' : 2.875rem,
    's47' : 2.9375rem,
    's48' : 3rem,
    's50' : 3.125rem,
    's53' : 3.3125rem,
    's55' : 3.4375rem,
    's56' : 3.5rem,
    's58' : 3.625rem,
    's60' : 3.75rem,
    's62' : 3.875rem,
    's65' : 4.0625rem,
    's70' : 4.375rem,
    's72' : 4.5rem,
    's74' : 4.625rem,
    's75' : 4.6875rem,
    's82': 5.125rem,
    's85': 5.313rem,
    's94' : 5.875rem,
    's92' : 5.75rem,
    's96' : 6rem,
    's100': 7rem,
    's80': 5rem,
    's120': 7.5rem,
);

$font_weights: (
  "w3": 300,
  "w4": 400,
  "w5": 500,
  "w6": 600,
  "w7": 700,
  "w8": 800,
  "w9": 900,
  "bold": bold,
);

$letter_spaces: (
  "05": 0.5px,
  "1": 1px,
);

$line_heights: (
  "18": 18px,
  "24": 24px,
  "30": 30px,
  "15": 15px,
);
$paddings_top: (
  "117": 117px,
  "108": 108px,
  "20": 20px,
  "50": 50px,
);
$margins: (
  "16": 16px,
  "30": 30px,
  "40": 40px,
  "20": 20px,
  "24": 24px,
  "32": 32px,
  '60': 60px,
  "64": 64px,
  "80":80px,
  "100": 100px
);

$paddings: (
  "40": 40px,
  "20": 20px,
  "24": 24px,
  "80":80px,
  "100": 100px,
);


@function font($fs_size) {
  @return map-get($font_sizes, $fs_size);
}
@each $fs_size, $val in $font_sizes {
  .f-#{$fs_size} {
    font-size: $val !important;
  }
}
@each $fs_sm_size, $val in $font_sizes {
  @media (max-width: 767.98px) {
    .f-sm-#{$fs_sm_size} {
      font-size: $val !important;
    }
  }
}
@each $fs_sm_size, $val in $font_sizes {
  @media (min-width: 767.98px) {
    .f-sm-only-#{$fs_sm_size} {
      font-size: $val !important;
    }
  }
}
@each $f_weights, $val in $font_weights {
  .f-#{$f_weights} {
    font-weight: $val !important;
  }
}
@each $f_sm_weights, $val in $font_weights {
  @media (max-width: 767.98px) {
    .f-sm-#{$f_sm_weights} {
      font-weight: $val !important;
    }
  }
}
@each $ls_space, $val in $letter_spaces {
  .ls-#{$ls_space} {
    letter-spacing: $val !important;
  }
}

@each $line_height, $val in $line_heights {
  .lh-#{$line_height} {
    line-height: $val !important;
  }
}

@each $padding_top, $val in $paddings_top {
  .pt-cz-#{$padding_top} {
    padding-top: $val;
  }
}

.font-trade-gothic {
  font-family: "Trade Gothic LT Std" !important;
}

.font-trade-bold {
  font-family: "Trade Gothic Bold Std" !important;
}

@each $margins, $val in $margins {
  .cz-mb-#{$margins} {
    margin-bottom: $val !important;
  }
  .cz-m-#{$margins} {
    margin: $val !important;
  }
  .cz-mt-#{$margins} {
    margin-top: $val !important;
  }
  .cz-me-#{$margins} {
    margin-right: $val !important;
  }
  .cz-ms-#{$margins} {
    margin-left: $val !important;
  }
  .cz-mx-#{$margins} {
    margin-left: $val !important;
    margin-right: $val !important;
  }
  .cz-my-#{$margins} {
    margin-top: $val !important;
    margin-bottom: $val !important;
  }
}

@each $margins, $val in $margins {
  @media (max-width: 767.98px) {
    .cz-m-sm-#{$margins} {
      margin: $val !important;
    }
    .cz-my-sm-#{$margins} {
      margin-top: $val !important;
      margin-bottom: $val !important;
    }
    .cz-mx-sm-#{$margins} {
      margin-left: $val !important;
      margin-right: $val !important;
    }
    .cz-mb-sm-#{$margins} {
      margin-bottom: $val !important;
    }
    .cz-mt-sm-#{$margins} {
      margin-top: $val !important;
    }
  }
  .cz-mt-#{$margins} {
    margin-top: $val !important;
  }
  .cz-my-#{$margins} {
    margin: $val 0 !important;
  }
}
  
@each $paddings, $val in $paddings {
  .cz-pb-#{$paddings} {
    padding-bottom: $val !important;
  }
  .cz-p-#{$paddings} {
    padding: $val !important;
  }
  .cz-px-#{$paddings} {
    padding-left: $val !important;
    padding-right: $val !important;
  }
}


@each $paddings, $val in $paddings {
  .cz-pb-#{$paddings} {
    padding-bottom: $val !important;
  }
  .cz-p-#{$paddings} {
    padding: $val !important;
}
}