@import "../../styles/base/_baseModule.scss";


.online_order_btn {
  @include btn_theme(Pri_clr(lt-black), 60px, Pri_clr(cod-grey));
  @extend %btn-large;
  @include font_sty(font(s16), null, $sm-fs: font(s14));
  width: max-content;
  min-width: 172px;
  padding: 9px 15px;
  margin-right: 15px;

  @media (max-width: 767.98px) {
    min-width: 117px;
    max-width: 130px;
    max-height: 31px;
    padding: 4px 15px;
  }

  @media (max-width: 450px) {
    white-space: nowrap;
    font-size: 12px;
    padding: 6px;
    box-sizing: content-box;
  }
}

.header_row {
  align-items: center;
  border-bottom: 1px solid #c9c8c696;
  background: #fff;
  .header_logo_wrap{
    @media (max-width: 767.98px){
      padding: 0 16px;
    } 
  }
}



// New style start
$modal_height: 500px;

.other_products_wrap {
  padding: 0 25px 15px;

  .other_products_item {
    background: #ffffff;
    border: 2px solid transparent;
    box-sizing: border-box;
    box-shadow: 0 0 3px #82828240;
    border-radius: 7px;
    padding: 8px 6px;

    &.active {
      border-color: Pri_clr(dark-blue);
    }

    .cart_img_wrap {
      img {
        min-height: 90px;
        max-height: 90px;

        @media (max-width: 500px) {
          min-height: 70px;
          max-height: 70px;
        }
      }
    }
  }

  .pro_item_btn {
    min-width: 160px;
  }

  h6 {
    line-height: normal;
  }

  @media (max-width: 767px) {
    padding: 0 25px 0px;
  }
}

.total_count_list {
  padding: 23px 25px 10px;

  li {
    display: flex;
    justify-content: space-between;
    font-size: font(s16) !important;
    margin-bottom: 6px;
    font-family: font-fam(Visby-cf) !important;

    &:nth-last-child(1) {
      border-top: 1px solid #21212114;
      font-weight: bold;
      line-height: 30px;
    }
  }
}

.checkout_btn_wrap {
  padding: 0 25px 40px;

  hr {
    background-color: #21212114;
    opacity: 1;
  }
}

.coupon_code_wrap {
  padding: 15px 25px;
  border: 1px solid #ebebeb;

  .coupon_code_field {
    &::-webkit-input-placeholder {
      color: #3d3935;
    }

    &::-moz-placeholder {
      color: #3d3935;
    }

    &:-ms-input-placeholder {
      color: #3d3935;
    }

    &:-moz-placeholder {
      color: #3d3935;
    }
  }
}

.use_reward {
  padding: 0 25px 15px;
}

.promo_code {
  @extend .use_reward;
}

.rewards_redeem {
  padding: 20px 25px;
  background: linear-gradient(180deg, #c2cebc4f 0%, #c2cebc 100%);

  .rewards_redeem_list {
    li {
      padding: 18px 10px;
      display: flex;
      background-color: #fff;
      box-shadow: 0px 0px 8.66509px #3d393533;
      border-radius: 3px;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      h6 {
        @media (max-width: 576.98px) {
          letter-spacing: 1.1px;
        }
      }

      img {
        width: 120px;

        @media (max-width: 576.98px) {
          width: 95px;
        }
      }

      button {
        padding: 5px 24px;
      }
    }
  }
}

.card_alert_wrapper {
  background: #f3f3f380;
  height: 230px;
  transition: all 0.3s ease-in-out;

  &.hide {
    height: 0;
    visibility: hidden;
    opacity: 0;
  }

  .card_alert_inn_wrap {
    padding: 20px 25px;

    h6 {
      button {
        width: 20px !important;
      }
    }
  }
}

.suggestion_row {
  margin-left: -4px;
  margin-right: -4px;

  .suggestion_column {
    padding: 4px;

    .other_products_item {
      background: #ffffff;
      border: 2px solid transparent;
      box-sizing: border-box;
      box-shadow: 0 0 3px #82828240;
      border-radius: 7px;
      padding: 8px 6px;
      h5 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        min-height: 55px;
        overflow: hidden;
      }
    }
  }
}

.other_products_Scrollbar {
  width: 100% !important;
  height: 265px !important;

  .Scrollbar_view {
    display: flex;
    flex-wrap: nowrap;
  }

  .Scrollbar_thumb {
    height: 4px !important;
    background-color: #cccccc;
    border-radius: 3px;
  }
}

.my_card_modal {
  :global{
    .modal-content{
      box-shadow: 0px 0px 10px rgba(61, 57, 53, 0.2);
    }
  }
  .modal_close_button {
    position: static;
    float: none;
  }

  .card_title {
    font-size: font(s30);

    @media (max-width: 767.98px) {
      font-size: font(s22);
    }
  }

  .card_increments {
    border: 1px solid #000;
    border-radius: 6px;
    width: 155px;
    justify-content: space-between;

    @media (max-width: 767.98px) {
      width: 73px;
      border: none;
      font-family: font-fam(Visby-cf);
      font-weight: 600;

      button {
        padding: 0;
        font-family: font-fam(Visby-cf) !important;
        font-size: 23px !important;
      }
    }
  }

  .empty_content_wrap {
    padding: 20px 0;
    max-width: 290px;
  }

  .items_list {
    padding: 13px 23px 0;

    .list_item {
      border: 1px solid #cccccc;
      box-shadow: 0px 0px 8.66509px rgba(61, 57, 53, 0.2);
      border-radius: 6px;
      padding: 18px 17px;
      margin-bottom: 14px;

      &.error_state {
        border: 2px solid Sec_clr(dark-red);

        h1 {
          color: Sec_clr(dark-red);
        }
      }
    }

    &.items_list_invite {
      padding-bottom: 0;
    }
  }

  .shadow_box {
    border: 1px solid #cccccc;
    box-shadow: 0px 0px 8.66509px rgba(61, 57, 53, 0.2);
    border-radius: 6px;
    padding: 18px 17px;
    margin-bottom: 14px;
    margin: 0 23px;
    width: calc(100% - 46px);
  }

  .modal_header,
  .checkout_btn_wrap {
    position: absolute;
    background: #fff;
    width: 100%;
    z-index: 99;
    right: 15px;
    top: 0;

    @media (min-width: 576px) {
      max-width: 485px;
    }
  }

  .checkout_btn_wrap {
    position: unset;
  }

  .cart_Modal_body {
    height:100vh ;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 100vh ;
    padding: 100px 0 80px 0px;
    @media (max-width:757.98px) {
      min-height:  calc(100vh - 60px);
       height: calc(100vh - 60px) ;
    }
    
    &.invite_body {
      .spacing_top_span {
        padding-top: 275px;
      }
    }
  }

  &.sticky_modal_content {
    .modal_header,
    .checkout_btn_wrap {
      position: fixed;
      background: #fff;
      width: 100%;
      z-index: 99;
      right: 15px;
      top: 0;

      @media (min-width: 576px) {
        max-width: 485px;
      }

      @media (max-width: 1024.98px) {
        right: 0;
      }
    }

    .checkout_btn_wrap {
      position: unset;
    }
  }
}

.redeem_item_box {
  background: #ffffff;
  border: 1px solid #c2cebc;
  box-shadow: 0px 0px 5px #3d39351a;
  border-radius: 3px;
  margin-bottom: 22px;
  padding: 20px 16px;

  h5 {
    letter-spacing: 1.1px;
  }
}

.invite_content_wrap {
  .invite_item_button {
    background: #ffffff;
    box-shadow: 0px 0px 8px #3d393533;
    border: 1px solid transparent;
    color: Pri_clr(lt-black);
    font-weight: 500;
    text-decoration: none !important;
    border-radius: 5px;
    width: 219px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:before {
      content: "";
      background: url("../../images/at-icon.svg") no-repeat;
      background-size: contain;
      background-position-x: center;
      width: 32px;
      height: 32px;
      display: inline-block;
      margin-bottom: 12px;
    }

    &.copy {
      margin-right: 12px;

      &:before {
        background-image: url("../../images/copy-icon.svg");
      }
    }

    &.share {
      margin-right: 12px;

      &:before {
        background-image: url("../../images/Share-icon.svg");
      }
    }

    &:hover,
    &:active,
    &:focus {
      color: Sec_clr(dark-red);
      border-color: Sec_clr(dark-red);

      &:before {
        filter: invert(11%) sepia(62%) saturate(6367%) hue-rotate(345deg)
          brightness(68%) contrast(64%);
      }
    }
  }
}

.card_inner_lis_des {
  min-height: 60px;
}

.wrapper_tracking_bar {
  @media (max-width: 767.98px) {
    .nav_wrapper_main_menu {
      padding-top: 0;

      &::before {
        height: 119px !important;
      }
    }
  }
}

.online_location_icon {
  padding: 0;
}

.other_products_item.active {
  button {
    background-color: Pri-clr(dark-blue) !important;
    border-color: Pri-clr(dark-blue) !important;
    color: #fff !important;
    opacity: 1;
  }
}


.wrapper_tracking_bar.no_order_track {
  @media (max-width: 767.98px) {
    padding-bottom: 71px !important;
  }

  .nav_wrapper_main_menu {
    @media (max-width: 767.98px) {
      min-height: 71px !important;
    }

    &::before {
      @media (max-width: 767.98px) {
        height: 71px !important;
      }
    }
  }
}

.order_detail_live {
  @media (max-width: 767.98px) {
    padding-left: 0 !important;
  }
}

.cart_btn_checkout {
  position: fixed !important;
  top: auto !important;
  bottom: 0 !important;
  padding-bottom: 0;
  right: auto !important;
}



.loyalty_banner_wrapper {
  span {
    color: #f0f0f0;

    @include media-breakpoint-down(md) {
      font-size: 8px !important;
      line-height: 1 !important;
    }
  }

  button {
    border-bottom: 1px solid #3d3935 !important;

    &:active {
      border-bottom: 1px solid #3d3935 !important;
    }

    &:hover {
      text-transform: uppercase;
    }

    @include media-breakpoint-down(md) {
      font-size: 8px !important;
      line-height: 1 !important;
      white-space: nowrap;
    }
  }
}

.bg_clr_light_yellow {
  background-color: #ffedbb !important;
}

.offers_scrolbar_height {
  height: 265px !important;
  width: 100% !important;

  .Scrollbar_view {
    display: flex;
    flex-wrap: nowrap;
    overflow-y: hidden !important;
  }

  .Scrollbar_thumb {
    height: 4px !important;
    background-color: #cccccc;
    border-radius: 3px;
  }

  @media (max-width: 400px) {
    height: 240px !important;
  }

  @media (min-width: 401px) and (max-width: 500px) {
    height: 250px !important;
  }

  @media (min-width: 501px) and (max-width: 600px) {
    height: 270px !important;
  }
}

.loyalty_reward_btn {
  &:hover {
    background-color: Pri-clr(dark-blue) !important;
    border-color: Pri-clr(dark-blue);
  }

  &:focus {
    background-color: Pri-clr(dark-blue) !important;
    border-color: Pri-clr(dark-blue);
  }
}

.radeemPointsBtn {
  @include media-breakpoint-down(md) {
    min-width: 100%;
    width: 100%;
  }
}

.mainHeaderWrapper {
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  @include media-breakpoint-down(md) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .headerInnerDiv {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    @media (min-width: 768px) and (max-width: 1180px) {
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .headerNavLinks {
    font-family: font-fam(Visby-cf);
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #3d3935;
    text-decoration: none;
    padding-left: 25px;
    position: relative;
    top: 4px;
    text-transform: uppercase;
    @media (min-width: 768px) and (max-width: 1180px) {
      font-size: 14px;
      padding-left: 20px;
      top: -4px;
    }
    svg {
      transition: all 0.3s ease-out;
    }
    &:hover {
      .chevIconDiv {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .locationNavSection {
    font-family: font-fam(Visby-cf);
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #3d3935;
    background: transparent;
    border: 0;
  }

  .borderBottomLocation {
    border-bottom: 1px solid #3d3935;
    margin-left: 3px;
    text-transform: uppercase;
  }

  .headerRight {
    gap: 16px;
  }


  .addToCartBtn {
    background-size: contain;
    border: none !important;
    background-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    font-size: font(s17);
    position: relative;
    padding-bottom: 5px !important;
    @media (max-width: 767.98px) {
      font-size: font(s14);
      padding-bottom: 4px !important; 
      padding: 0;
    }
  
    span {
      position: absolute;
      top: 52%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    svg{
      min-height: 36px;
    }
  }
}

.headerScrollAnimation {
  transition: transform 0.6s ease-in-out;
  position: fixed;
  z-index: 999;
  width: 100%;
}

