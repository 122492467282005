@import '../../styles/base/cloudfront_url_base.scss';
@import '../../styles/base/baseModule';

.error{
  font-size: font(s10);
  color: #AA182C;
  float: left;
}

  .bottom_text {
      font-size: font(s13) !important;
      color: #3D3935;
      margin-top: 24px;
  
      a {
        color: #3D3935 !important;
        text-decoration: underline;
      }
  }
.modal_href {
  color: #3D3935 !important;
  text-decoration: underline;
}