@import "../../styles/base/cloudfront_url_base.scss";
@import "../../styles/base/baseModule";

.recent_order_wrap {
  border: 1px solid #cccccc;
  box-shadow: 0px 0px 8.66509px #3d393533;
  padding: 32px 28px;
  @include media-breakpoint-down(md) {
    border: none;
    border-bottom: 1px solid #cccccc;
    box-shadow: unset;
    padding: 25px 0 25px 0;
  }
}
.recents_items {
  .recent_item_col {
    &:last-child {
      .recent_order_wrap {
        @include media-breakpoint-down(md) {
          border-bottom: none;
        }
      }
    }
  }
}
.order_name {
  letter-spacing: 0.5px !important;
}
.profile_content_wrap {
  .inn_content_wrap {
    max-width: 560px;
    img {
      margin-bottom: 35px;
      width: 120px;
      @include media-breakpoint-down(md) {
        width: 74px;
        margin-bottom: 24px;
      }
    }
    h1 {
      margin-bottom: 18px;
      @include media-breakpoint-down(md) {
        margin-bottom: 13px;
      }
    }
    p {
      width: 70%;
      margin-bottom: 38px;
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 31px;
      }
    }
  }
}
.item_main_div {
  border: 1px solid #3d393580;
  box-shadow: 0px 0px 5px #3d39351a;
  border-radius: 15px;
  overflow: hidden;
  padding: 20px;
  position: relative;
}
.nav_wrapper {
  max-width: 730px;
  min-width: 730px;
  width: 730px;
  padding: 35px 16px 0;
  border: 1px solid #cccccc;
  border-top-width: 0;
  box-shadow: 0px 0px 8.66509px #3d393533;
  @include media-breakpoint-down(md) {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    border: none;
    border-bottom: 1px solid #cccccc;
    box-shadow: unset;
    background: url("#{$REACT_APP_CLOUD_FRONT}/images/rewards/reward-mobo-bg.png")
      no-repeat;
    background-size: cover;
  }
  .rewards_title {
    @include media-breakpoint-down(md) {
      margin-bottom: 15px;
      span {
        width: 95px;
        margin-left: 14px;
      }
    }
  }
}
.rewards_item {
  background: #ffffff;
  box-shadow: 0px 0px 8.66509px #3d393533;
  border-radius: 15px;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  padding: 0 16px;
  min-height: 100%;
  cursor: pointer;
  position: relative;
  @include media-breakpoint-down(md) {
    margin-bottom: 15px;
  }
  &.item_disabled {
    cursor: not-allowed;
    &::after {
      content: "";
      background-color: #fff;
      opacity: 0.8;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }
  }
  h5 {
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 8px;
  }
  @include media-breakpoint-down(md) {
    h5 {
      line-height: 18.2px;
      margin-bottom: 16px;
    }
    border-radius: 7px;
    padding: 0 8px;
  }
  &.disabled {
    img,
    h5 {
      opacity: 0.4;
    }
  }
}
.filter_btn {
  background: url("../../images/SlidersHorizontal.svg") no-repeat;
  background-size: contain;
  width: 30px;
  min-width: 30px;
  height: 28px;
  border: none !important;
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  font-size: font(s17);
  &:hover,
  &:focus,
  &:active,
  &.active {
    filter: invert(16%) sepia(96%) saturate(2481%) hue-rotate(337deg)
      brightness(87%) contrast(100%);
  }
}
.collapse_filter {
  position: fixed;
  background-color: #fff;
  top: calc(var(--get-nav-height) + 67.5px);
  width: 100%;
  z-index: 99;
  @include media-breakpoint-down(md) {
    top: calc(var(--get-nav-height) + 65px);
  }
  h6,
  .rewards_filters_content {
    max-width: 448px;
  }
  .filter_header {
    padding: 20px;
    box-shadow: 0px 4px 8.66509px #3d393521;
    position: relative;
  }
  .rewards_filters {
    background-color: #fff;
    height: 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      width: calc(100% + 96px);
      left: -48px;
      height: 0;
      background-color: #3d393533;
      top: 100%;
      z-index: 9;
    }
    &.active {
      height: 341px;
      &::after {
        height: 100vh;
      }
      @include media-breakpoint-down(md) {
        height: 365px;
      }
    }
    .rewards_filters_content {
      padding: 20px 20px 30px;
      position: relative;
      @include media-breakpoint-down(md) {
        padding: 20px 20px 28px;
      }
    }
  }
}
.my_rewards_wrap {
  padding: 50px 96px 0;
  text-align: left;
  .no_rewards_content {
    max-width: 562px;
    min-width: 562px;
    width: 562px;
  }
  .reward_details_image{
    min-width: 150px;
    img{
      max-height: 150px;
    }
  }
  @include media-breakpoint-down(md) {
    .no_rewards_content {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
    }
    padding: 30px 24px 0;
    .reward_details_image {
      min-width: 125px;
      width: 125px;
      img{
        max-height: 100px;
      }
    }
  }
  .reward_card_content {
    @include media-breakpoint-down(md) {
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 10px;
      text-align: left;
      width: 100%;
      max-width: 160px;
      h5 {
        margin-bottom: 0 !important;
      }
    }
  }
  .past_rewards_wrap {
    @extend .no_rewards_content;
    margin-top: 100px;
    @include media-breakpoint-down(md) {
      margin-top: 53px;
    }
    ul {
      li {
        display: flex;
        align-items: center;
        background: #ffffff;
        border: 1px solid #c2cebc;
        border-radius: 3px;
        padding: 3px 15px;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
        @include media-breakpoint-down(md) {
          padding: 3px 10px;
          margin-bottom: 8px;
          img {
            width: 95px;
          }
        }
      }
    }
  }
  .added_rewards_items {
    &.reward_slider_listing {
      .added_item_inner {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        @include media-breakpoint-down(md) {
          margin-bottom: 0;
        }
      }
      .add_reward_button {
        text-transform: uppercase;
        margin-bottom: 8px;
        width: 100%;
        max-width: 100%;
      }
    }
    .added_rewards_item {
      background: #ffffff;
      box-shadow: 0px 0px 8.66509px #3d393533;
      border-radius: 15px;
      padding: 17px 30px;
      min-height: 100%;
      position: relative;
      @include media-breakpoint-down(md) {
        border-radius: 7px;
        padding: 16px;
      }
      &.item_disabled {
        cursor: not-allowed;
        &::after {
          content: "";
          background-color: #fff;
          opacity: 0.8;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
        }
      }
    }
  }
  &.items_wrap {
    padding: 32px 35px;
    height: calc(100vh - 4.5rem);
    max-height: calc(100vh - 4.5rem);
    @include media-breakpoint-down(md) {
      padding: 24px;
      height: calc(100vh - 237px);
      max-height: calc(100vh - 237px);
    }
    .items_wrap_title {
      margin-bottom: 32px;
    }
    [class*="col-"] {
      margin-bottom: 30px;
      @include media-breakpoint-down(md) {
        margin-bottom: 16px;
      }
    }
    .added_rewards_items {
      text-align: center;
      .added_item_inner {
        flex-direction: column;
        text-align: center;
        @include media-breakpoint-down(md) {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .add_to_order_btn {
          border: 1px solid;
          background-color: Pri-clr(dark-blue);
          border-radius: 50px;
          color: #fff;
          border-color: Pri-clr(dark-blue);
          font-family: "visby_cfextra_bold";
          text-align: center !important;
          display: inline-block;
          font-weight: 500;
          transition: all 0.3s ease-in-out;
          text-decoration: none !important;
          box-shadow: 0px 0px 11px rgba(61, 57, 53, 0.2);
          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }
      }
      .add_reward_button {
        max-width: 156px !important;
        min-width: auto;
        width: 156px;
        font-size: 14px;
        padding: 9px 22px;
        margin: 0 auto;
        margin-top: 8px;
        @include media-breakpoint-down(md) {
          max-width: 220px !important;
          width: 100%;
        }
      }
    }
  }
}
.upload_rewards_wrap {
  .upload_reward_content {
    background: #ffffff;
    border: 1px solid #f7b984;
    box-shadow: 0px 0px 5px #3d39351a;
    border-radius: 15px;
    padding: 56px 50px 41px;
    max-width: 414px;
    @include media-breakpoint-down(md) {
      border-radius: 3px;
      padding: 30px 22px;
    }
    input {
      border: 1px solid #3d3935;
      border-radius: 0;
      padding: 0 15px;
      height: 44px;
      min-height: auto;
      line-height: normal;
      outline: none !important;
      box-shadow: none !important;
      color: #e37736;
      text-align: center;
      &:focus {
        border-color: #e37736;
        color: #e37736;
      }
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
}
.upload_rewards_options {
  .upload_reward_content {
    &:first-child {
      margin-right: 29px;
      @include media-breakpoint-down(md) {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
    .upload_content_icon {
      width: 87px;
      min-width: 87px;
      height: 87px;
      border-radius: 50%;
      background-color: #3d3935;
      display: grid;
      place-items: center;
      margin-bottom: 34px;
      @include media-breakpoint-down(md) {
        width: 62px;
        min-width: 62px;
        height: 62px;
        margin-bottom: 0;
        img {
          height: 29px;
        }
      }
    }
    @include media-breakpoint-down(md) {
      padding: 22px;
      width: 100%;
      max-width: 100%;
    }
  }
}
.swiper_wrapper {
  width: calc(100% + 96px) !important;
  @include media-breakpoint-down(md) {
    width: 100% !important;
  }
  .reward_card_content {
    .add_reward_button {
      display: none;
    }
    @include media-breakpoint-down(md) {
      width: calc(100% - 125px);
    }
  }
}
.other_products_Scrollbar {
  height: calc(100vh - 4.5rem) !important;
  max-height: calc(100vh - 4.5rem);
  @include media-breakpoint-down(md) {
    height: calc(100vh - 280px);
    max-height: calc(100vh - 360px);
  }
}
.Scrollbar_thumb {
  right: 4px;
  bottom: 22px;
  top: 22px;
  border-radius: 3px;
}

.reward_card_img_size {
  max-height: 200px;
  width: 80%;
}

:global{
  .my_reward_modal {
      @include media-breakpoint-down(md) {
        z-index: 1;
        top: 257px;
        height: calc(100vh - 220px);
        min-height: calc(100vh - 220px);
        max-height: calc(100vh - 220px);
        overflow: hidden;
        .modal-dialog {
          min-height: calc(100vh - 220px);
        }
        .modal-content {
          min-height: calc(100vh - 220px);
          overflow: hidden;
          border-top: 1px solid #21212114;
          padding: 0;
        }
      }
    }
}