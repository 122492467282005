@import "../../styles/base/baseModule";

.item_details_modal {
  .item_img_wrap {
    width: 100%;
    position: relative;
    height: 100%;
    margin-bottom: 0;
    @media (max-width: 575.98px) {
      padding-bottom: 100%;
    }
    img {
      height: 100%;
      width: 100%;
      max-width: 100%;
      position: absolute;
      object-fit: contain;
      left: 0;
      top: 0;
    }
  }
}
.item_img_wrap {
  width: 100%;
  .is_not_active{
    opacity: 0.6;
    cursor: not-allowed;
  }
}
.item_img_wrap img {
  max-width: 100%;
  height: auto;
  width: 100%;
  max-width: 100%;
  &.badge_img {
    width: 86px;
    position: absolute;
    top: 15px;
    left: 12px;
    @media (max-width: 767.97px) {
      top: 10px;
      left: 7px;
    }
  }
}
.item_title_wrap {
  h6 {
    @include font-sty($fs_lh: normal !important);
  }
}

.modal_subtitle.item_subtitle_wrap {
  font-size: font(s21);
  @media (max-width: 1600px) {
    line-height: 20px !important;
    font-size: font(s17);
  }
}
.modal_subtitle,
.modal_bold_title {
  color: #2f2f2e;
}
.item_subtitle_wrap {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  height: 58px !important; 
  overflow: hidden;
  position: relative;
  // &::after {
  //   content: "";
  //   text-align: right;
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  //   width: 70%;
  //   height: 1.2em;
  //   background: linear-gradient(to right, #ffffff00, #ffffff 50%);
  // }
  .item_subtitle_button {
    // position: absolute;
    // bottom: 0;
    // z-index: 1;
    // right: 0;
    color: Pri_clr(dark-blue);
  }
}

.showMore {
  height: 25px;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  .item_subtitle_button {
    // position: absolute;
    // bottom: 0;
    // z-index: 1;
    // right: 0;
    color: Pri_clr(dark-blue);
  }
}
.item_subtitle_wrap.modal_descr {
  font-size: font(s18);
  color: #939598;
  @media (max-width: 1600px) {
    font-size: font(s14);
  }
}
.item_subtitle_wrap.modal_bold_title {
  font-family: "Raleway";
  font-size: font(s18);
  margin-top: 15px;
  @media (max-width: 1600px) {
    font-size: font(s15);
  }
  @media (max-width: 576px) {
    font-size: font(s13);
  }
}

@media (max-width: 576px) {
  .modal_item_img_wrap {
    text-align: center;
    margin-bottom: 10px;
  }
}
.item_main_div {
  cursor: pointer;
  padding: 20px;
  box-shadow: 0px 0px 5px #3d39351a;
  border-radius: 15px;
  position: relative;
  background-color: #fff;
  @media (max-width: 767.98px) {
    margin-bottom: 0;
    border-radius: 3px;
  }
  .item_order_btn {
    @include font_sty(
      font(s19),
      font_clr(white-clr),
      "Raleway",
      $fs_sty: uppercase,
      $fs_space: 1px
    );
    background-color: font_clr(sb-light);
    border: 2px solid font_clr(sb-light);
    padding: 8px 20px;
    width: 100%;
    margin-top: 10px;
    @extend %Removed_extra_sty;
    &:hover {
      @include font_sty(null, font_clr(sb-light));
      background-color: transparent;
    }
  }
}
.item_calor_wrap {
  & > p {
    @include font_sty($fs_weight: 500, $fs_lh: normal);
  }
}
.cursor_pointer {
  cursor: pointer;
  width: auto;
  height: 20px;
  margin-left: 8px;
}
.item_icon_wrap {
  height: 26px;
  align-items: center;
  @media (max-width: 991.98px) {
    height: 20px;
  }
}
.item_sticky_button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.item_details_row_wrap {
  @media (max-width: 575.98px) {
    padding-bottom: 20px;
    [class*="col-"] {
      margin-bottom: 11px;
    }
  }
}

.order_pickup_wrapper {
  .order_type {
    @include font_sty(
      font(s18),
      Pri_clr(lt-black),
      font-fam(Visby-cf),
      $sm_fs: font(s16),
      $fs_lh: 25.45px,
      $sm_lh: 19.9px,
      $fs_weight: 600,
      $fs_space: normal
    );
    text-decoration: none;
    width: 129px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px #3d393533;
    border-radius: 7px;
    cursor: pointer;
  }
}
@media (min-width: 991.98px) {
  .delivery_alert_text {
    max-width: 60%;
    margin: 0 auto;
  }
  .no_item__wrap {
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    @media (max-width: 575.98px) {
      width: 100%;
    }
    p {
      line-height: 1.5;
    }
  }
}

.date_icon_container {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
}
.zindex_calender_icon{
  span{
    z-index: auto !important;
  }
}

.input_order_date{
  z-index: 9;
}
@-moz-document url-prefix() {
  .date_icon_container{
    display: none;
  }
}
.nav_disabled{
  opacity: 0.6;
  pointer-events: none;
}