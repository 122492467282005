.embed_video {
    position: relative;
    .embed_video_wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        .video {
            width: 100%;
            height: 100%;
        }
    }
}