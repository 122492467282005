@import "../../styles/functions/_@mixin-module";

.nav_wrapper {
  padding: 9px 0 0 0;
  @media (max-width: 767.98px) {
    padding: 0 0px 0;
    z-index: 2;
  }
}
.header_content {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767.98px) {
    justify-content: start;
  }
  .nav_toggle_btn {
    border: none !important;
  }
}
.mobo_app_section {
  margin-top: 5px;
  background-color: #f3f3f3;
  position: relative;
  padding: 31px 22px 31px 10px;
  .app_content_wrap {
    p {
      margin-top: 11px;
      margin-bottom: 5px;
      font-weight: 500;
    }
    .app_btn {
      @include font_sty(
        font(s16),
        Pri_clr(lt-black),
        font-fam(Vcf),
        $fs_sty: uppercase,
        $fs_space: 1px,
        $fs_lh: 19px
      );
      text-decoration: underline;
      outline: none !important;
      box-shadow: none !important;
    }
  }
  .site_logo_wrap {
    img {
      width: 100px;
      min-width: 100px;
      margin-right: 10px;
    }
  }
}

.Footer_nav_wrapper {
  .mobo_app_section {
    display: none !important;
  }
  .menu_links_item {
    @media (max-width: 767.98px) {
      padding: 4px 5px;
      line-height: 1;
      display: block;
      text-align: left;
    }
  }
  @media (max-width: 767.98px) {
    position: static;
  }
}
.nav_toggle_btn {
  span {
    background-image: url("#{$REACT_APP_CLOUD_FRONT}/images/icons/hamburger-icon.svg") !important;
  }
  &.nav_expand {
    span {
      background-image: url("#{$REACT_APP_CLOUD_FRONT}/images/icons/x-icon.svg") !important;
    }
  }
}
.sub_menu_btn {
  background: transparent !important;
  border: none;
  border-bottom: 1px solid #e1e1e1;
  outline: none !important;
  box-shadow: none !important;
  padding: 16px 0 !important;
  width: calc(100% - 48px);
  border-radius: 0;
  @include font_sty(
    font(s16),
    Pri_clr(lt-black),
    font-fam(Vcf),
    $fs_sty: uppercase,
    $fs_space: 1px,
    $fs_lh: 25.78px
  );
  &:hover,
  &:focus,
  &:active {
    color: Pri-clr(dark-blue) !important;
    border-color: #e1e1e1;
  }
  &::after {
    content: "";
    border: none;
    background: url("#{$REACT_APP_CLOUD_FRONT}/images/icons/Arrow-down-angle.svg")
      no-repeat;
    width: 14px;
    height: 7px;
    background-size: contain;
  }
  &[aria-expanded="true"]::after {
    transform: rotate(180deg);
  }
}
.sub_menu_list {
  .sub_menu_item {
    @extend .sub_menu_btn;
    @include font_sty(font(s14));
    padding: 12px 16px !important;
    margin-top: 0 !important;
    display: inline-block;
    text-decoration: none;
    width: 100%;
    cursor: pointer;
    &::after {
      content: none;
    }
  }
  .menuListingOnMobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      max-width: 58px;
    }
    h6 {
      color: #3d3935;
      @include font_sty(font(s16));
      margin-left: 16px;
      margin-bottom: 0;
    }
  }
}
.start_order_btn_wrap {
  border-bottom: 1px solid #ebebeb;
  padding: 25px 30px;
  margin: 0 23px;
  width: calc(100% - 46px);
}

.sub_menu_order_list {
  justify-content: space-around;
  padding: 0 16px;
  li {
    .sub_menu_order_item {
      display: flex;
      flex-direction: column;
      font-size: font(s16);
      font-family: font_fam(Cls);
      color: Pri_clr(lt-black);
      text-align: center;
      height: 100%;
      justify-content: space-between;
      text-decoration: none !important;
      &:hover,
      &:focus,
      &:active {
        color: Sec_clr(dark-red);
        img {
          filter: invert(13%) sepia(67%) saturate(4112%) hue-rotate(340deg)
            brightness(71%) contrast(75%);
        }
      }
      span {
        display: inline-block;
        img {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}

.login_btn_wrapper {
  border-bottom: 1px solid #e1e1e1;
  .login_btn {
    color: Pri_clr(dark-blue);
    text-decoration: none;
  }
}
.user_menu_listing {
  text-align: left;
  li {
    .sub_menu_item {
      font-size: font(s16);
      text-transform: none;
      font-family: font-fam(Vcf);
      color: Pri_clr(dark-blue);
      margin: 0 !important;
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 0 !important;
      img {
        margin-right: 10px;
      }
    }
  }
}
.menu_button_mobile {
  @media (max-width: 767.98px) {
    padding-top: 0;
    margin-top: 0 !important;
  }
}

#navIcon {
  width: 34px;
  height: 34px;
  position: relative;
  // margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  margin-bottom: 4px;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #3d3935;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
}
#navIcon span:nth-child(1) {
  top: 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#navIcon span:nth-child(2) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#navIcon span:nth-child(3) {
  top: 30px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#navIcon.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 4px;
  left: 5px;
}

#navIcon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#navIcon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 29px;
  left: 5px;
}

.careerAccordion {
  // margin-inline: 24px;
  border-bottom: 1px solid #e1e1e1;
  :global {
    .accordion {
      .accordion-item {
        border: none;
        .accordion-header {
          .accordion-button {
            padding-block: 12px;
            padding-inline: 0;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 500 !important;
            color: #3d3935;
            letter-spacing: 1px;
            &:after {
              width: 18px;
              height: 12px;
              background-size: 16px;
            }
            &:is(:focus, :focus-visible, :focus) {
              box-shadow: none;
              border: none;
            }
          }
          .accordion-button:not(.collapsed):not(
              .navbar-toggler.collapsed:focus
            ) {
            box-shadow: none;
            background-color: #fff;
            color: Pri_clr(dark-blue);
          }
          .accordion-button:not(.collapsed):not(
              .navbar-toggler.collapsed:focus
            )::after {
            filter: grayscale(1);
          }
        }
        .accordion-body {
          padding: 0;
        }
      }
      a {
        padding-block: 17px;
        padding-inline: 0;
        font-size: 1rem;
        text-transform: capitalize;
        color: #3d3935;
        letter-spacing: 1px;
        text-decoration: none;
        font-weight: 700 !important;
      }
    }
  }
}
